import CurrentPomelloVersion from '@components/CurrentPomelloVersion';
import useTranslation from '@helpers/useTranslation';
import { Link } from '@reach/router';
import React, { FC, Fragment, ReactNode } from 'react';
import { Container, Content, Heading, Section } from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';
import DownloadViewDownloadButtons from './DownloadViewDownloadButtons';
import DownloadViewEmailReminderForm from './DownloadViewEmailReminderForm';

type Platform = 'android' | 'iOS' | 'linux' | 'mac' | 'windows';

interface DownloadViewProps {
  DownloadButtons?: ReactNode[];
  platform: Platform;
}

const DownloadView: FC<DownloadViewProps> = ({ DownloadButtons, platform }) => {
  const { t } = useTranslation('download');

  const availablePlatforms: Platform[] = ['mac', 'windows', 'linux'];

  const platformLabels: Record<Platform, string> = {
    android: 'Android',
    iOS: 'iOS',
    linux: 'Linux',
    mac: 'Mac',
    windows: 'Windows',
  };

  const platformLabel = platformLabels[platform];

  return (
    <Section>
      <Container textAlign="center">
        <Content>
          <Heading>{t('pomelloForPlatform', { platform: platformLabel })}</Heading>
          {DownloadButtons ? (
            <DownloadViewDownloadButtons DownloadButtons={DownloadButtons} />
          ) : (
            <DownloadViewEmailReminderForm platformLabel={platformLabel} />
          )}
          <CurrentPomelloVersion />
          <p>
            <span>Looking for the </span>
            {availablePlatforms
              .filter(availablePlatform => availablePlatform !== platform)
              .map((availablePlatform, index) => (
                <Fragment key={availablePlatform}>
                  {index === 0 ? '' : index === availablePlatforms.length - 1 ? ' or ' : ', '}
                  <Link
                    title={t('downloadPomelloForPlatform', {
                      platform: platformLabels[availablePlatform],
                    })}
                    to={`${DOWNLOAD_PAGE}/${availablePlatform}`}
                  >
                    {platformLabels[availablePlatform]}
                  </Link>
                </Fragment>
              ))}
            <span> desktop apps?</span>
          </p>
        </Content>
      </Container>
    </Section>
  );
};

export default DownloadView;
