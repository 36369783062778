import useTranslation from '@helpers/useTranslation';
import React, { FC, ReactNode } from 'react';
import { Columns } from 'react-bulma-components';

interface DownloadViewDownloadButtonsProps {
  DownloadButtons: ReactNode[];
}

const DownloadViewdDownloadButtons: FC<DownloadViewDownloadButtonsProps> = ({
  DownloadButtons,
}) => {
  const { t } = useTranslation('download');

  return (
    <>
      <p>{t('downloadDescription')}</p>
      <Columns centered m={6}>
        {DownloadButtons.map((DownloadButton, index) => (
          <Columns.Column display="flex" flexDirection="column" key={index} narrow>
            {DownloadButton}
          </Columns.Column>
        ))}
      </Columns>
    </>
  );
};

export default DownloadViewdDownloadButtons;
