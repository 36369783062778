import useSendDownloadReminderEmail from '@api/useSendDownloadReminderEmail';
import TextField from '@components/TextField';
import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Button, Columns, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string;
}

interface DownloadViewEmailReminderFormProps {
  platformLabel: string;
}

const DownloadViewEmailReminderForm: FC<DownloadViewEmailReminderFormProps> = ({
  platformLabel,
}) => {
  const { t } = useTranslation(['download', 'validation']);

  const {
    isLoading,
    isSuccess,
    mutate: sendDownloadReminderEmail,
  } = useSendDownloadReminderEmail();

  const formProps = useForm<FormValues>();

  const handleFormSubmit: SubmitHandler<FormValues> = ({ email }) => {
    sendDownloadReminderEmail(email);
  };

  return (
    <>
      <p>{t('comingSoon', { platform: platformLabel })}</p>
      <Columns centered textAlign="left" m={6}>
        <Columns.Column size={6}>
          {isSuccess && (
            <Message color="success">
              <Message.Body>{t('reminderEmailSent')}</Message.Body>
            </Message>
          )}
          <p>{t('sendReminderEmailDescription')}</p>
          <FormProvider {...formProps}>
            <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
              <TextField<FormValues>
                autoComplete="email"
                id="email"
                label={t('common:email')}
                name="email"
                type="email"
                validation={{ required: t('validation:required', { field: t('common:email') }) }}
              />
              <Form.Control>
                <Button color="primary" loading={isLoading}>
                  {t('sendEmail')}
                </Button>
              </Form.Control>
            </form>
          </FormProvider>
        </Columns.Column>
      </Columns>
    </>
  );
};

export default DownloadViewEmailReminderForm;
